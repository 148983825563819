<template>
<div class="after-min">
  <div class="after-top">
    La verificación es exitosa, haga clic para ir al sitio web
  </div>
  <div class="">
    <button class="btn" @click="home">CONTINUAR</button>
  </div>
</div>
</template>

<style lang="less">
    .after-min{
      text-align: center;

    }
    .after-top{
      color: #666;
      font-size: 20px;
      margin: 120px 0 50px;
    }
    .btn{
      padding: 10px 20px;
      border: 1px #666 solid;
      background: #fff;
      cursor: pointer;
    }
</style>
<script>
  export default {
    name: 'Regafter',
    data() {
      return {

      }
    },
  
    methods: {
      home(){
        this.$router.push("/");
      },
      
    }
  }

</script>
